import '../css/style.css';
import {PriceLoad as PriceLoadController} from './controller/price-load';
import {SitePricesPlacement as SitePricesPlacementController} from './controller/site-prices-placement';

window.setLoadedPrices = (placeSelector = '') => {
    const priceLoadController = new PriceLoadController();

    if (priceLoadController.isLoadNeeded()) {
        priceLoadController.load()
            .then((prices) => {
                new SitePricesPlacementController(prices, placeSelector).placePrices();
            });
    } else {
        const prices = priceLoadController.getFromCache();
        new SitePricesPlacementController(prices, placeSelector).placePrices();
    }
};

document.addEventListener('DOMContentLoaded', () => {
    window.setLoadedPrices();
});
